'use strict';

angular.module('enervexSalesappApp').controller('ProductSubtypesCtrl', function($scope, ProductSubtype, _, ProductType) {
	$scope.all = [];
	$scope.offset = 0;
	$scope.limit = 50;
	$scope.ctx = {
		active: true,
		term: ""
	}
	$scope.activeOptions = [
	{
		name:"active",
		value: true
	}, {
		name: "inactive",
		value: false
	}]
	$scope.productTypes = ProductType.query()
	function fetch() {
		ProductSubtype.query({
			offset: $scope.offset,
			limit: 100
		}).$promise.then(function(items){
			if (items.length > 0) {
				$scope.all = $scope.all.concat(items);
				$scope.offset = $scope.offset + $scope.limit;
				$scope.all = _.sortBy($scope.all, function(o){
					return o.code.toLowerCase()
				})
				fetch();
			} else {
				// $scope.items = $scope.all
				$scope.applyFilter()
			}
		});
	}
	fetch();
	$scope.applyFilter = function() {
		$scope.items = _.filter($scope.all, function(subtype){
			if ($scope.ctx.active != null) {
				if (subtype.active != $scope.ctx.active) {
					return false
				}
			}
			if ($scope.ctx.term != null && $scope.ctx.term.trim() != '') {
				if (subtype.name.indexOf($scope.ctx.term.trim()) == -1 && subtype.code.indexOf($scope.ctx.term.trim()) == -1) {
					return false
				}
			}
			if ($scope.ctx.productType && $scope.ctx.productType != '') {
				var existing = _.find(subtype.productTypes, function(o){
					return o._id == $scope.ctx.productType
				})
				return (existing) ? true : false
			}
			return true
		})
	}
	$scope.delete = function(item) {
		ProductSubtype.delete({
			id: item._id
		});
		$scope.all = _.filter($scope.all, function(r){
			return r._id != item._id
		})
		$scope.items = $scope.all;
		// $scope.applyFilter()
	}
	// $scope.applyFilter = function() {
	// 	var nameReg = ($scope.term && $scope.term != '') ? RegExp($scope.term, "i") : null;

	// 	$scope.fans = _.filter($scope.allVentMaterials, function(rule){
	// 		if (nameReg && !nameReg.test(rule.code) ) {
	// 			return false;
	// 		} else if ($scope.computeAs== 'BWH' && !rule.BWH) {
	// 			return false;
	// 		} else if ($scope.computeAs== 'COM' && !rule.COM) {
	// 			return false;
	// 		} else if ($scope.computeAs== 'DRY' && !rule.DRY) {
	// 			return false;
	// 		} else {
	// 			return true
	// 		}
	// 	})
	// }
});
